import React, { ReactElement } from "react";
import { Button } from "@ui/button/Button";
import Icon from "@components/icons/Icon";
import { ModalCloseButton } from "@ui/modal/Modal.styles";
import { IconSize } from "@/components/icons/Icon.type";

export interface CloseButtonProps {
  onClose: () => void;
  size?: IconSize;
}

const CloseButton = ({ onClose, size = "xSmall" }: CloseButtonProps): ReactElement => (
  <ModalCloseButton>
    <Button
      onClick={onClose}
      variant="text"
      isIcon
    >
      <Icon
        name="close"
        size={size}
      />
    </Button>
  </ModalCloseButton>
);

export default CloseButton;
